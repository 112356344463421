import styled from "styled-components";

export const ItemBoxDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 120px;
  height: 64px;
  padding-left: 16px;
  align-items: center;
  font-size: 14px;
  background-color: var(--pale-grey);
`
