import {call} from "redux-saga/effects";

export default function* handleHttpError(code: number, message?: string) {
    switch (code) {
        default:
        case 400:
        case 422:
            yield call(() => alert(message !== undefined ? message : 'error'))
            break;
    }
}