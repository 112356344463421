import axios from 'axios';
import {BaseResponse} from "../BaseResponse";

export async function signIn(signInRequest: SignInRequest) {
    const response = await axios.get<BaseResponse<Member>>(`/member/${signInRequest.memberId}/${signInRequest.password}`)
    return response.data
}

export interface SignInRequest {
    memberId: string,
    password: string
}

export interface Member {
    uid: string
    memberId: string
    password: string
    isAdmin: Boolean
}
