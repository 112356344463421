import React, {useEffect, useState} from "react";
import {Qr} from "../../api/qr/getQrList";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {GridOptions} from "ag-grid-community";
import QrEditBtn from "./component/QrEditBtn";
import ErpTable from "../../components/table/ErpTable";
import Button from "../../components/style/button/Button";
import QrModal from "./component/QrModal";
import {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_QR_MEMBER} from "../../resources/LocalStorageConstants";


interface QrScreenProps {
    qrList: Qr[]
    onPutQr: (qr: Qr) => void
}

function QrScreen(props: QrScreenProps) {

    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState<Qr>()
    const methods = useForm({
        mode: "onBlur"
    })
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER).isAdmin ?
            [
                {
                    headerName: "상호",
                    field: "shopName",
                    width: 268
                },
                {
                    headerName: "주소",
                    field: "position",
                    width: 268
                },
                {
                    headerName: "QR 주인",
                    field: "memberId",
                    width: 268
                },
                {
                    headerName: "권한",
                    field: "uid",
                    cellRenderer: "qrBtnRender",
                    // cellRendererFramework: QrEditBtn,
                    width: 268
                },
            ] : [
                {
                    headerName: "상호",
                    field: "shopName",
                    width: 357
                },
                {
                    headerName: "주소",
                    field: "position",
                    width: 357
                },
                {
                    headerName: "QR 주인",
                    field: "memberId",
                    width: 357
                },
            ],

        frameworkComponents: {
            qrBtnRender: (props: any) => QrEditBtn(props, setShowModal, setModalData)
        },

        rowData: props.qrList
    })

    useEffect(() => {
        setGridOptions({
            ...gridOptions,
            rowData: props.qrList
        })
    }, [props.qrList])

    function handleModalClose() {
        setShowModal(false)
    }

    function handleModalSubmit(data: Qr) {
        console.log(data, "fork ModalSubmit")

        props.onPutQr(data)
    }

    return (
        <FormProvider {...methods}>
            <form>
                <HeaderDiv>
                    <FixedWeightText fontSize={"24px"} fontWeight={500}>
                        QR 관리
                    </FixedWeightText>
                </HeaderDiv>

                <TableInfoDiv>
                    <ResultText>
                        <FixedWeightText fontSize={"14px"} fontWeight={500}>
                            QR 리스트
                        </FixedWeightText>
                    </ResultText>
                </TableInfoDiv>

                <ErpTable
                    tableWidth={1080}
                    tableHeight={450}
                    gridOptions={gridOptions}
                />

                {
                    modalData &&
                    <QrModal
                        showModal={showModal}
                        data={modalData}
                        onCloseModal={handleModalClose}
                        onConfirm={handleModalSubmit}
                    />
                }

            </form>
        </FormProvider>
    )
}

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;
  align-items: center;
`

const TableInfoDiv = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 8px;
`

const ResultText = styled.div`
  //display: flex;
  //margin-top: 32px;
  //margin-bottom: 8px;
  width: 50%;
  margin-bottom: 10px;
`

const OptionBtnGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`

const OptionBtn = styled(Button)`
  height: 30px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`

export default QrScreen