import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {Member} from "../login/signIn";

export async function getChargingList(member: Member) {

    /*
    * Array(9)
    * 0: (2) [Array(5), "가게0"]
    * 1: (2) [Array(1), "위치1"]
    * 2: (2) [Array(20), "위치2"]
    * 3: (2) [Array(21), "위치3"]
    * 4: (2) [Array(23), "위치4"]
    * 5: (2) [Array(21), "위치5"]
    * 6: (2) [Array(20), "위치6"]
    * 7: (2) [Array(20), "위치7"]
    * 8: (2) [Array(20), "위치8"]*/

    const response = await axios.post<BaseResponse<Array<Array<any>>>>(`/qr/charging`, member)

    console.log(response.data)

    return response.data
}

export type ResponseChargingList = {
    responseChargingList: Array<Array<ChargingWithDate>>
}

export type ChargingList = {
    chargingList: Array<ChargingWithDate>
}

export type ChargingWithDate = {
    chargingVolume: string
    phoneNumber: string
    chargingDate: string
}

export type ChargingListType = {
    position: string,
    chargingList: ChargingWithDate
}