import React, {ReactNode} from 'react';
import Footer from "./component/Footer";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import DefaultOuterDiv from "../style/div/DefaultOuterDiv";
import Header from "./component/Header";

interface ComponentProps {
    children: ReactNode;
    width?: number;
    height?: number;
}

function Index(props: ComponentProps) {

    return (
        <div style={{width: props.width, height: props.height}}>
            <Header />
            <DefaultOuterDiv>
                <DefaultWrapperDiv>
                    {props.children}
                </DefaultWrapperDiv>
            </DefaultOuterDiv>
            <Footer/>
        </div>
    );
}

export default Index;