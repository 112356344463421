import React from "react";
import styled from "styled-components";
import Button from "../../components/style/button/Button";
import * as XLSX from "xlsx"
import {TablePropsType} from "./ChargingContainer";

interface ExportXlsxBtnType {
    chargingList: TablePropsType[]
    fileName: string
}

interface KoreanTitleType {
    위치: string,
    충전량: string,
    전화번호: string,
    충전일자: string
}

export function ExportXlsxBtn({
                                  chargingList,
                                  fileName,
                              }: ExportXlsxBtnType) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';

    function getKoreanTitle(chargingList: TablePropsType[]): KoreanTitleType[] {
        let changedArr: KoreanTitleType[] = [];

        for (const data of chargingList) {
            changedArr.push({
                위치: data.position,
                충전량: data.chargingVolume,
                전화번호: data.phoneNumber,
                충전일자: data.chargingDate
            })
        }

        return changedArr
    }

    function exportToXlsx() {
        // workSheet 만듬
        const ws = XLSX.utils.json_to_sheet(getKoreanTitle(chargingList));

        // workBook 만들고 workSheet 추가함
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        const xlsxURL = window.URL.createObjectURL(data)

        const a = document.createElement("a")
        a.href = xlsxURL
        a.download = `${fileName}.xlsx`
        a.click()
        a.remove()
        window.URL.revokeObjectURL(xlsxURL);

        // FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <XlsxBtn onClick={(e) => exportToXlsx()}>엑셀 다운로드</XlsxBtn>
    )
}

const XlsxBtn = styled(Button)`
  height: 30px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`