import {ActionType, createAsyncAction, createReducer} from "typesafe-actions";
import {AxiosError} from "axios";
import {AsyncState, asyncState, createAsyncReducer, transformToArray} from "../../lib/reducerUtils";
import createAsyncSaga from "../../lib/createAsyncSaga";
import {takeEvery} from "redux-saga/effects";
import {Member, signIn, SignInRequest} from "../../api/login/signIn";

const SIGN_IN = 'signIn/SIGN_IN'
const SIGN_IN_SUCCESS = 'signIn/SIGN_IN_SUCCESS';
const SIGN_IN_ERROR = 'signIn/SIGN_IN_ERROR';

export const signInAsync = createAsyncAction(
    SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_ERROR
)<SignInRequest, Member, AxiosError>();

type SignInAction = ActionType<typeof signInAsync>

type SignInState = {
    signInResponse: AsyncState<Member>
};

const getAsyncSignInSaga = createAsyncSaga(signInAsync, signIn)

const initialState: SignInState = {
    signInResponse: asyncState.initial()
};

export const signInReducer = createReducer<SignInState, SignInAction>(initialState).handleAction(
    transformToArray(signInAsync),
    createAsyncReducer(signInAsync, "signInResponse")
)

export function* signInSaga() {
    yield takeEvery(SIGN_IN, getAsyncSignInSaga);
}