import React from 'react';
import BaseModal from "../../../components/modal/BaseModal";
import ModalHeader from "../../../components/modal/Header";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {FormProvider, useForm} from "react-hook-form";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import {Qr} from "../../../api/qr/getQrList";
import styled from "styled-components";
import Button from "../../../components/style/button/Button";
import Input from "../../../components/style/input/Input";


interface QrModalProps {
    showModal: boolean
    data: Qr
    onCloseModal: () => void
    onConfirm: (data: Qr) => void
}

interface QrPutData {
    shopName: string,
    position: string,
    memberId: string
}

function QrModal(props: QrModalProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    function closeModal() {
        props.onCloseModal()
    }

    function onSubmit(data: QrPutData) {
        closeModal()
        props.onConfirm({
            uid: props.data.uid,
            memberId: data.memberId,
            shopName: data.shopName,
            position: data.position,
            chargingList: props.data.chargingList,
        })
    }

    return (
        <BaseModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <ModalHeader title={"QR 관리"} onCancel={closeModal}/>
                        <ContentDiv>
                            <HeaderContentText fontSize={"24px"} fontWeight={500}>QR 수정</HeaderContentText>
                            <ServicePolicyDiv>
                                <FixedWeightText fontSize={"14px"} fontWeight={500}>QR</FixedWeightText>
                            </ServicePolicyDiv>

                            <ItemDiv>
                                <ItemBoxDiv>상호</ItemBoxDiv>
                                <ItemInput name="shopName" defaultValue={props.data.shopName} ref={methods.register}/>
                            </ItemDiv>
                            <ItemDiv>
                                <ItemBoxDiv>주소</ItemBoxDiv>
                                <ItemInput name="position" defaultValue={props.data.position} ref={methods.register}/>
                            </ItemDiv>
                            <ItemDiv>
                                <ItemBoxDiv>QR 주인</ItemBoxDiv>
                                <ItemInput name="memberId" defaultValue={props.data.memberId} ref={methods.register}/>
                            </ItemDiv>

                            <ButtonDiv>
                                <CancelButton onClick={closeModal}>
                                    <FixedWeightText color={"var(--red)"} fontSize={"14px"}
                                                     fontWeight={500}>취소</FixedWeightText>
                                </CancelButton>
                                <SignUpButton color={"var(--blue)"} type={"submit"}>
                                    <FixedWeightText color={"var(--white)"} fontSize={"14px"}
                                                     fontWeight={500}>저장</FixedWeightText>
                                </SignUpButton>
                            </ButtonDiv>
                        </ContentDiv>
                    </form>
                </FormProvider>
            </ModalDiv>
        </BaseModal>
    );
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 832px;
  border-radius: 4px;
  background-color: var(--white);
`
const ContentDiv = styled.div`
  display: flex;
  height: 710px;
  flex-direction: column;
  padding: 22px 32px 32px 32px;
`

const ServicePolicyDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`


const ItemDiv = styled.div`
  //flex: 1;
  //align-items: center;
  display: flex;
  align-items: stretch;
`

const ItemInput = styled(Input)`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const HeaderContentText = styled(FixedWeightText)`
  height: 36px;
  align-items: center;
  margin-bottom: 50px;
`

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`
const CancelButton = styled(Button)`
  width: 120px;
  height: 48px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`
const SignUpButton = styled(CancelButton)`
  margin-left: 8px;
  background-color: ${(props) => props.color};
`
export default QrModal