import React from "react";
import styled from 'styled-components';
import Title from "../../title/Title";

interface ComponentProps {
}

function Header(props: ComponentProps) {
    return (
        <Div>
            <Title/>
        </Div>
    );
}

const Div = styled.div`
  display: flex;
  background-image: linear-gradient(to top, #1450a5, #14469b);
  height: 64px;
  align-content: center;
  align-items: center;
  justify-content: center;
`
export default Header;