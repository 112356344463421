import React, {useEffect} from "react";
import LoginScreen from "./LoginScreen";
import {signIn, SignInRequest} from "../../api/login/signIn";
import {useHistory} from "react-router";
import {LINK_MAIN} from "../../resources/LinkConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {signInAsync} from "../../redux/account/signInModule";


interface LoginContainerProps {

}

function LoginContainer(props: LoginContainerProps) {

    const {data, loading, error} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)
    const dispatch = useDispatch()
    const history = useHistory()

    function onLogin(request: SignInRequest) {
        dispatch(signInAsync.request(request))
    }

    useEffect(() => {

        if (data != null) {
            history.push(LINK_MAIN)
        }
    }, [data])

    return (
        <>
            <LoginScreen
                onLogin={onLogin}
                loading={loading}
            />
        </>
    )
}

export default LoginContainer