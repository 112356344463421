import React, {useEffect} from "react";
import QrScreen from "./QrScreen";
import {getQrList, Qr} from "../../api/qr/getQrList";
import {useAsync} from "../../hooks/useAsync";
import {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_QR_MEMBER} from "../../resources/LocalStorageConstants";
import {getQr} from "../../api/qr/getQr";
import {AdminWithQr, postQr} from "../../api/qr/postQr";
import {putQr} from "../../api/qr/putQr";


interface QrContainerProps {

}

function QrContainer(props: QrContainerProps) {

    const member = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER)

    const {data: qrList, error: qrListError, isLoading: qrListIsLoading, execute: executeGetQrList} = useAsync(getQrList, member, true)
    const {data: putResult, error: putError, isLoading: putIsLoading, execute: executePutQr} = useAsync(putQr)

    function onPutQr(qr: Qr) {
        console.log(qr, "fork")
        executePutQr(qr).then(() =>
            executeGetQrList(member)
        )
    }

    return member && qrList ? (
        <>
            <QrScreen
                qrList={qrList}
                onPutQr={onPutQr}
            />
        </>
    ) : (
        <>
        </>
    )
}

export default QrContainer