import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import rootReducer, {rootSaga} from "./redux";
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import axios from "axios";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

axios.defaults.baseURL = process.env.REACT_APP_API_URL

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
