import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import logo from "../../assets/images/header/img_logo@3x.png";
import login_picture from "../../assets/images/header/ic_24_profile@3x.png";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_QR_MEMBER, LOCAL_STORAGE_KEY_JWT,} from "../../resources/LocalStorageConstants";
import {LINK_MAIN} from "../../resources/LinkConstants";

interface TitleComponentProps {

}


function Title(props: TitleComponentProps) {
    const [userName, setUserName] = useState<string | null>(getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER) ?
        getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER).memberId :
        null)
    const {data: member} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)
    const {isLogOut} = useSelector((state: RootState) => state.accountReducers.logOutReducer)

    function onClickLogOutLink() {
        localStorage.removeItem(LOCAL_STORAGE_KEY_QR_MEMBER)
        localStorage.removeItem(LOCAL_STORAGE_KEY_JWT)
        setUserName(null)
    }

    useEffect(() => {
        if (member != null) {
            setUserName(member.memberId)
            localStorage.setItem(LOCAL_STORAGE_KEY_QR_MEMBER, JSON.stringify(member))
        }

        if (isLogOut) {
            onClickLogOutLink()
        }
    }, [member, isLogOut])

    return (
        <DefaultWrapperDiv direction={"row"} justifyContent={"space-between"}>
            <Link to={LINK_MAIN}
                  style={{textDecoration: 'none', justifyContent: 'center'}}>
                <CenterDiv>
                    <LogoImage src={logo}/>
                    <TitleText>QR</TitleText>
                </CenterDiv>
            </Link>
            {!userName ?
                <CenterDiv>
                    로그인을 해주세요
                </CenterDiv> :
                <CenterDiv>
                    <PictureImage src={login_picture}/>
                    {/*<Link to="/logout" style={{textDecoration: 'none', justifyContent: 'center'}}>*/}
                    <NameText>{userName && `${userName}님`}</NameText>
                    {/*</Link>*/}
                    <Link to={LINK_MAIN}
                          onClick={onClickLogOutLink}
                          style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameText>로그아웃</NameText>
                    </Link>
                </CenterDiv>
            }
        </DefaultWrapperDiv>
    )
}

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`
const LogoImage = styled.img`
  width: 32px;
  height: 32px;
`

const PictureImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const TitleText = styled.h1`
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-left: 16px
`

const NameText = styled.button`
  width: fit-content;
  min-width: 80px;
  height: fit-content;
  border-width: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  align-items: center;
  background: transparent;
  color: white;
`
export default Title;