import React from "react";
import LoginContainer from "../login/LoginContainer";
import {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_QR_MEMBER} from "../../resources/LocalStorageConstants";
import Button from "../../components/style/button/Button";
import styled from "styled-components";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import Divider from "../../components/style/div/Divider";
import {LINK_CHARGING, LINK_MAIN, LINK_QR} from "../../resources/LinkConstants";
import {useHistory} from "react-router";


interface MainPageProps {

}

function MainPage(props: MainPageProps) {

    const member = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER)
    const history = useHistory()

    function onQrBtn() {
        history.push(LINK_QR)
    }

    function onChargeBtn() {
        history.push(LINK_CHARGING)
    }

    return member ? (
        <>
            <SearchDiv>
                <SearchButton onClick={onQrBtn}>
                    <FixedWeightText color={"var(--white)"} fontWeight={500}>
                        QR 관리
                    </FixedWeightText>
                </SearchButton>
                <SearchButton onClick={onChargeBtn}>
                    <FixedWeightText color={"var(--white)"} fontWeight={500}>
                        충전량 이력
                    </FixedWeightText>
                </SearchButton>
            </SearchDiv>
        </>
    ) : (
        <>
            <LoginContainer />
        </>
    )
}

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;
  align-items: center;
`

const SearchDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px;
`

const SearchButton = styled(Button)`
  width: 300px;
  height: 200px;
  margin-left: 8px;
  background-color: var(--blue);
  align-items: center;
`

export default MainPage
