import React from 'react'
import styled from "styled-components";
import Button from "../../../components/style/button/Button";
import {Qr} from "../../../api/qr/getQrList";


function QrEditBtn(
    props: any,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setModalData: (value: (((prevState: (Qr | undefined)) => (Qr | undefined)) | Qr | undefined)) => void
) {

    function handleEdit() {
        // modal 띄우고
        // 거기에 해당 uid 값의 data 다 넘겨주기
        setShowModal(true)
        setModalData(props.data)

        console.log("props !!!!!!!")
        console.log(props)
    }

    return (
        <React.Fragment>
            <SearchButton onClick={handleEdit} style={{marginRight: "5px"}}>
                수정
            </SearchButton>
        </React.Fragment>
    )
}

const SearchButton = styled(Button)`
  background-color: var(--blue);
  color: var(--white);
`

export default QrEditBtn
