import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {Member} from "../login/signIn";

export async function getQrList(member: Member) {
    const response = await axios.post<BaseResponse<Qr[]>>(`/qr`, member)
    return response.data
}

export type Charging = {
    chargingVolume: string,
    phoneNumber: string
}

export type Qr = {
    uid: string
    memberId: string
    shopName: string
    position: string
    chargingList: Array<Charging>
}
