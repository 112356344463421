import React from "react";
import styled from 'styled-components';
import logo from '../../../assets/images/header/img_logo@3x.png';
import DefaultWrapperDiv from "../../style/div/DefaultWrapperDiv";

interface ComponentProps {
    width?: number;
    margin_left?: number;
}

function Footer(props: ComponentProps) {
    return (

        <Div >
            <DefaultWrapperDiv justifyContent={"flex-start"}>
            <div style={{display: "flex", flexDirection: "row"}}>
                <FooterText style={{marginLeft: 0}}>회사소개</FooterText>
                <FooterText>공지사항</FooterText>
                <FooterText>서비스 이용약관</FooterText>
                <FooterText>개인정보처리방침</FooterText>
            </div>
                <div style={{display: "flex", marginTop: "52px", marginBottom: "24px"}}>
                    <img src={logo} width={52} height={52}/>
                </div>

            <div>
                <div style={{display: "flex", height: "20px"}}>
                    <FooterSecondText style={{marginLeft : 0}}>주식회사 디앨</FooterSecondText>
                </div>

                <div style={{display: "flex", flexDirection: "row", height: "20px", marginTop: "8px"}}>
                    <FooterSecondText style={{marginLeft: 0}}>대표 임근영</FooterSecondText>
                    <FooterSecondText>사업자등록번호 3015-4448-125925</FooterSecondText>
                    <FooterSecondText>경기도 안산시 단원구 고잔로54 에이스타워 1002호</FooterSecondText>
                </div>

                <div style={{display: "flex", flexDirection: "row", height: "20px", marginTop: "8px"}}>
                    <FooterSecondText style={{marginLeft : 0}}>제 2019-경기-0001호</FooterSecondText>
                    <FooterSecondText>FAX 070-4339-5087</FooterSecondText>
                    <FooterSecondText>서비스이용문의 031-355-3329</FooterSecondText>
                </div>
            </div>
            </DefaultWrapperDiv>

        </Div>
    );
}

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin-top : 80px;
  
  background-color: #2d2d2d;
  height: 356px;
`

const FooterText = styled.text`
  width: 120px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  margin-top:40px;
  margin-left: 52px;
`

const FooterSecondText = styled.text`
  width: fit-content;
  height: 20px;
  margin-left:14px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
`
export default Footer;