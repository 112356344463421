import React, {useEffect, useState} from "react";
import ErpTable from "../../components/table/ErpTable";
import {GridOptions} from "ag-grid-community";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
import {TablePropsType} from "./ChargingContainer";
import {ExportXlsxBtn} from "./ExportXlsxBtn";

interface ChargingScreenProps {
    chargingList: TablePropsType[]
}

function ChargingScreen(props: ChargingScreenProps) {

    useEffect(() => {
        console.log(props.chargingList, "chargingList !!!")
    }, [props.chargingList])

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "주소",
                field: "position",
                width: 268
            },
            {
                headerName: "충전량",
                field: "chargingVolume",
                width: 268
            },
            {
                headerName: "충전자 전화번호",
                field: "phoneNumber",
                width: 268
            },
            {
                headerName: "날짜",
                field: "chargingDate",
                width: 268
            },
        ],

        rowData: props.chargingList,

        pagination: true,

        paginationPageSize: 100
    })

    useEffect(() => {
        setGridOptions({
            ...gridOptions,
            rowData: props.chargingList
        })
    }, [props.chargingList])


    return (
        <>
            <HeaderDiv>
                <FixedWeightText fontSize={"24px"} fontWeight={500}>
                    충전량 이력
                </FixedWeightText>
            </HeaderDiv>

            <TableInfoDiv>
                <ResultText>
                    <FixedWeightText fontSize={"14px"} fontWeight={500}>
                        충전량 이력 리스트
                    </FixedWeightText>
                </ResultText>
                <OptionBtnGroup>
                    <ExportXlsxBtn
                        chargingList={props.chargingList}
                        fileName={"충전_이력_리스트"}/>
                </OptionBtnGroup>
            </TableInfoDiv>

            <ErpTable
                tableWidth={1080}
                tableHeight={527}
                gridOptions={gridOptions}
            />
        </>
    )
}

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;
  align-items: center;
`

const TableInfoDiv = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 8px;
`

const ResultText = styled.div`
  width: 50%;
  margin-bottom: 10px;
`

const OptionBtnGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`


export default ChargingScreen