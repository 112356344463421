import React, {useEffect, useState} from 'react';
import {
    ColumnApi,
    GridApi, GridOptions,
    GridReadyEvent
} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";

interface ErpTableComponentProps {
    tableWidth: number
    tableHeight: number
    gridOptions: GridOptions
}

function ErpTableComponent(props: ErpTableComponentProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    useEffect(() => {

        // rowData 혹은 datasource 의 변화 감지한 뒤 갱신
        if (gridApi) {
            if (props.gridOptions.rowData) {
                gridApi.setRowData(props.gridOptions.rowData)
            } else if (props.gridOptions.datasource) {
                gridApi.setDatasource(props.gridOptions.datasource)
            }
        }
    }, [gridApi, props.gridOptions.rowData, props.gridOptions.datasource]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)

        if (props.gridOptions.datasource) {
            event.api.setDatasource(props.gridOptions.datasource)
        }
    }

    return (
        <div className="ag-theme-alpine" style={{height: props.tableHeight, width: props.tableWidth}}>
            <AgGridReact
                rowSelection={props.gridOptions.rowSelection ? props.gridOptions.rowSelection : 'multiple'}
                rowHeight={props.gridOptions.rowHeight? props.gridOptions.rowHeight : 42}
                defaultColDef={{...props.gridOptions.defaultColDef, lockPosition: true}}
                scrollbarWidth={4}

                onGridReady={onGridReady}
                gridOptions={props.gridOptions}
            >
            </AgGridReact>
        </div>
    );
}

export default ErpTableComponent