import React from 'react';
import styled from 'styled-components';
import Close from "../../assets/images/signup/ic-48-close@3x.png";
import DefaultImage from "../style/image/DefaultImage";
import FixedWeightText from "../style/text/FixedWeightText";

interface ModalHeaderComponentProps {
    title: string
    onCancel?: () => void;
}

function ModalHeader (props : ModalHeaderComponentProps) {
    function handleClick() {
        if (props.onCancel) {
            props.onCancel()
        }
    }

    return (
        <Header>
            <HeaderText>
                {props.title}
            </HeaderText>
            <DefaultImage src={Close} width={"27.9px"} height={"27.9px"} onClick={handleClick}/>
        </Header>
    );
}

const Header = styled.div`
    display: flex;
    height: 48px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--deep-blue);
    justify-content: space-between;
    align-items: center;
    padding-left : 12px;
    padding-right : 12px;
`

const HeaderText = styled(FixedWeightText)`
  color : var(--pale-grey)
`
export default ModalHeader