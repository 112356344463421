import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import Index from "../components/layout";
import LoginContainer from "../pages/login/LoginContainer";
import {
    LINK_CHARGING,
    LINK_MAIN, LINK_QR,
    LINK_SIGN_IN,
} from "../resources/LinkConstants";
import MainPage from "../pages/main/MainPage";
import QrContainer from "../pages/qr/QrContainer";
import ChargingContainer from "../pages/charging/ChargingContainer";


interface RootComponentProps {

}

function Root(props: RootComponentProps) {
    return (
        <BrowserRouter>
            <Index>
                <Switch>
                    <Route exact path={LINK_MAIN} component={MainPage}/>
                    <Route path={LINK_SIGN_IN} component={LoginContainer}/>

                    <Route path={LINK_QR} component={QrContainer}/>
                    <Route path={LINK_CHARGING} component={ChargingContainer}/>

                    <Redirect path="*" to="/"/>
                </Switch>
            </Index>
        </BrowserRouter>
    );
}

export default Root;