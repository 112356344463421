import React, {useEffect, useState} from "react";
import ChargingScreen from "./ChargingScreen";
import {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_QR_MEMBER} from "../../resources/LocalStorageConstants";
import {useAsync} from "../../hooks/useAsync";
import {getChargingList} from "../../api/charging/getChargingList";


interface ChargingContainerProps {

}

export interface TablePropsType {
    position: string
    chargingVolume: string
    phoneNumber: string
    chargingDate: string
}

function ChargingContainer(props: ChargingContainerProps) {

    const [chargingList, setChargingList] = useState<Array<TablePropsType>>([])
    const member = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_QR_MEMBER)
    const {data: responseChargingList} = useAsync(getChargingList, member, true)

    useEffect(() => {

        setChargingList(getChargingListFormat())

    }, [responseChargingList])

    function getChargingListFormat(): TablePropsType[] {
        let tmpList: Array<TablePropsType> = []

        if (responseChargingList) {
            for (let outer = 0; outer < responseChargingList.length; outer++) {
                for (let inner = 0; inner < responseChargingList[outer][0].length; inner++) {
                    const charging = responseChargingList[outer][0][inner]

                    tmpList.push({
                        position: responseChargingList[outer][1],
                        chargingVolume: charging.chargingVolume,
                        phoneNumber: charging.phoneNumber,
                        chargingDate: `${charging.chargingDate.substring(0, 10)} ${charging.chargingDate.substring(11, 19)}`
                    })
                }
            }
        }

        return tmpList
    }

    return member && chargingList ? (
        <>
            <ChargingScreen
                chargingList={chargingList}
            />
        </>
    ) : (
        <>
        </>
    )

}

export default ChargingContainer