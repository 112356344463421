import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import {signInReducer, signInSaga} from "./signInModule";
import {logOutReducer} from "./logoutModule";

export const accountReducers = combineReducers({
    signInReducer,
    logOutReducer
})

export function* accountSaga() {
    yield all([
        signInSaga(),
    ]);
}